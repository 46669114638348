import React from "react";
// import Navbar from "./Navbar";
import MerakiNav from "./MerakiNavbar";

const MainLayout = ({ children }) => {
  return (
    <div style={{ minHeight: "100vh" }} className="bg-satkus_5">
      <header className="sticky top-0 z-50">
        <MerakiNav />
      </header>
      <main style={{ padding: "20px" }} className="relative">
        {/* Content of the page */}
        {children}
      </main>
    </div>
  );
};

export default MainLayout;
