import React from "react";
import mainLogo from "../logos/white.png";
import { AdvancedImage } from "@cloudinary/react";
import { Cloudinary } from "@cloudinary/url-gen";
import { fill } from "@cloudinary/url-gen/actions/resize";

const ComingSoon = () => {
  const cloudinaryCloudName = process.env.REACT_APP_CLOUDINARY_CLOUD_NAME;
  const cld = new Cloudinary({
    cloud: {
      cloudName: `${cloudinaryCloudName}`,
    },
  });
  const myImage = cld.image("Naktis/n-2_zw1vwo");
  myImage.resize(fill().width(500).height(750));
  return (
    <section className="bg-satkus_2 dark:bg-satkus_5 ">
      <div className="container min-h-screen px-6 py-12 mx-auto lg:flex lg:items-center lg:gap-12 ">
        <div className="w-full lg:w-1/2 lg:ml-24">
          <img
            src={mainLogo}
            alt="logotipas"
            className="w-1/2 ml-auto mr-auto opacity-40 pb-3 lg:pb-12"
          />
          <h1
            className="mt-3 text-center text-4xl pb-2 font-chica text-tekstas_6 dark:text-tekstas_2 md:text-5xl animate-pulse"
            style={{ textShadow: "5px 6px 10px gray" }}
          >
            Coming soon...
          </h1>
          {/* INSTAGRAM LINK */}
          <a
            href="https://www.instagram.com/satkus_media/"
            target="_blank"
            rel="noreferrer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-10 w-10 text-tekstas_2 ml-auto mr-auto mt-6"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
            </svg>
          </a>
        </div>
        {/* PHOTO */}
        <div className="relative flex justify-center w-full h-full mt-8 lg:w-full lg:mt-0">
          <AdvancedImage cldImg={myImage} />
        </div>
      </div>
    </section>
  );
};

export default ComingSoon;
