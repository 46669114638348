import React from "react";
import { useState } from "react";
import mainLogo from "../logos/white.png";

// REIKIA PASIDARYTI isOpen dalykus kai buna mobile view ir atsiranda hamburger menu.

const MerakiNav = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleMobileMenu = () => {
    setIsOpen(!isOpen);
  };
  return (
    <nav className="bg-satkus_4 shadow-xl">
      <div className="container px-6 py-4 mx-auto md:flex md:justify-between md:items-center">
        <div className="flex items-center justify-between">
          <a href="/">
            <img
              className="w-auto h-10 sm:h-12 opacity-80"
              src={mainLogo}
              alt=""
            />
          </a>

          {/* <!-- Mobile menu button --> */}
          <div className="flex lg:hidden md:hidden">
            <button
              onClick={toggleMobileMenu}
              type="button"
              className="text-tekstas_6 dark:text-tekstas_2 hover:text-gray-600 dark:hover:text-gray-400 focus:outline-none focus:text-gray-600 dark:focus:text-gray-400"
              aria-label="toggle menu"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-6 h-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M4 8h16M4 16h16"
                />
              </svg>
            </button>
          </div>
        </div>

        {/* <!-- Mobile Menu open: "block", Menu closed: "hidden" --> */}
        <div
          className={`${
            isOpen ? "block pt-8 pl-2" : "hidden"
          } lg:flex lg:items-center md:flex`}
        >
          <div className="flex flex-col md:flex-row md:mx-6">
            <a
              className="my-2 text-tekstas_6 transition-colors duration-300 transform dark:text-tekstas_4 hover:text-satkus_6 dark:hover:text-satkus_6 md:mx-4 md:my-0"
              href="/"
            >
              Home
            </a>
            {/* <a
              className="my-2 text-tekstas_6 transition-colors duration-300 transform dark:text-tekstas_4 hover:text-satkus_6 dark:hover:text-satkus_6 md:mx-4 md:my-0"
              href="/"
            >
              Portretai
            </a>
            <a
              className="my-2 text-tekstas_6 transition-colors duration-300 transform dark:text-tekstas_4 hover:text-satkus_6 dark:hover:text-satkus_6 md:mx-4 md:my-0"
              href="/"
            >
              Meninė
            </a>
            <a
              className="my-2 text-tekstas_6 transition-colors duration-300 transform dark:text-tekstas_4 hover:text-satkus_6 dark:hover:text-satkus_6 md:mx-4 md:my-0"
              href="/"
            >
              Blog
            </a>
            <a
              className="my-2 text-gray-700 transition-colors duration-300 transform dark:text-tekstas_4 hover:text-satkus_6 dark:hover:text-satkus_6 md:mx-4 md:my-0"
              href="/"
            >
              Renginiai
            </a> */}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default MerakiNav;
