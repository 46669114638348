import MainLayout from "../components/MainLayout";

const Contact = () => {
  return (
    <MainLayout>
      <div>
        <h1>Contact</h1>
      </div>
    </MainLayout>
  );
};

export default Contact;
