import "./App.css";
import MainLayout from "./components/MainLayout";
import ComingSoon from "./pages/ComingSoon";

function App() {
  return (
    <div className="App">
      <MainLayout>
        <ComingSoon />
      </MainLayout>
    </div>
  );
}

export default App;
// MERAKI UI
